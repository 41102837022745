const imagePath = '/imgs'

import heroImg from '../assets/images/hero.webp'

export const webAssets = {
  /**
   * LAYOUTS
   */
  logoLight: `${imagePath}/logo.webp`,
  logoDark: `${imagePath}/logo-dark.webp`,
  google: `${imagePath}/google.svg`,
  location: `${imagePath}/location.svg`,
  whatsapp: `${imagePath}/whatsapp.webp`,
  Messenger: `${imagePath}/Messenger.svg`,
  facebookFooter: `${imagePath}/FaceBook.svg`,
  instagramFooter: `${imagePath}/Instagram.svg`,
  linkedinFooter: `${imagePath}/Linkedin.svg`,

  /**
   * BLOCKS
   */

  // welcome
  heroImg,
  // HowItWorks
  howItWorks1: `${imagePath}/how-it-works-1.webp`,
  howItWorks2: `${imagePath}/how-it-works-2.webp`,
  howItWorks3: `${imagePath}/how-it-works-3.webp`,
  // About Us
  aboutUs1: `${imagePath}/about-us-1.webp`,
  aboutUs2: `${imagePath}/about-us-2.webp`,
  aboutUs3: `${imagePath}/about-us-3.webp`,
  // WhyUs
  whyUsImg: `${imagePath}/why-us.webp`,
  wifi: `${imagePath}/wifi.webp`,
  socialNetwork: `${imagePath}/social-network.webp`,
  timeFast: `${imagePath}/time-fast.webp`,
  handshakeAngle: `${imagePath}/handshake-angle.webp`,
  global: `${imagePath}/global.webp`,
  // CallCenter
  CallCenterImg: `${imagePath}/Call Center.webp`,
  callCalling: `${imagePath}/call-calling.webp`,
  clock: `${imagePath}/clock.webp`,
  CallCenterImg: `${imagePath}/Call Center.webp`,
  // Service Details
  subtract: `${imagePath}/Subtract.webp`,

  // Inside dashboard
  sentSuccessfully: `${imagePath}/sent-successfully.svg`,
}
