import Image from 'next/image'
import { List } from 'antd'

const NotificationBody = ({ notifications }) => {
  return notifications.length > 0 ? (
    <List
      size='small'
      itemLayout='horizontal'
      dataSource={notifications}
      renderItem={(item) => (
        <List.Item
          className={`list-clickable ${item.seen === 0 ? 'bg-green_100' : ''}`}
        >
          <div className='text-xs leading-5'>
            <span className='text-[#252733] font-bold'>{item.title}</span>
            <span className='text-gray_500'> {item.body}</span>
          </div>
          <small className='ml-8 text-[0.625rem] text-right min-w-[4.375rem]'>
            {item.created_at}
          </small>
        </List.Item>
      )}
    />
  ) : (
    <div className='py-68 text-center'>
      <Image
        src='https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg'
        alt='empty'
        width={87}
        height={83}
      />

      <p className='text-gray_500 text-sm font-medium mt-3'>
        {t('You have viewed all notifications')}
      </p>
    </div>
  )
}

export default NotificationBody
