import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Dropdown, Image } from 'antd'
import Cookies from 'js-cookie'
import { useSelector } from 'react-redux'

import countries from '../../config/Countries'
import ArrowGreen from '../svgs/ArrowGreen'
import LanguageOverlay from './LanguageOverlay'

const NavLanguage = () => {
  const { locale, push, pathname, asPath } = useRouter()

  const { profile } = useSelector((state) => state.auth)

  const [navOpen, setNavOpen] = useState(false)
  const [currentLanguage, setCurrentLanguage] = useState(null)

  // GET CURRENT LANGUAGE
  useEffect(() => {
    let code

    if (profile) {
      Cookies.set('i18next_SMARTCAF_LANG', profile.lang)
      code = profile.lang

      if (profile.lang !== locale)
        push(pathname, asPath, { locale: profile.lang })
    } else {
      code = Cookies.get('i18next_SMARTCAF_LANG') || 'it'
    }

    const language = countries.find((ele) => ele.code === code)

    document.querySelector('html').setAttribute('dir', language.dir)
    document.querySelector('html').setAttribute('lang', language.code)
    setCurrentLanguage(language)
  }, [profile, locale])

  return (
    false && (
    // currentLanguage && (
      <Dropdown
        overlay={<LanguageOverlay {...{ currentLanguage, setNavOpen }} />}
        trigger={['click']}
        open={navOpen}
        onOpenChange={(open) => setNavOpen(open)}
      >
        <div
          key={currentLanguage.key}
          className={`${
            navOpen ? 'bg-green_100' : ''
          } flex items-center rounded p-8 transition-all cursor-pointer`}
        >
          <Image
            className='!w-[1.563rem] !h-[1rem]'
            src={currentLanguage.image}
            alt={currentLanguage.name}
          />
          <span className={'px-16 text-base font-medium'}>
            {currentLanguage.apprev}
          </span>
          <ArrowGreen
            style={`${navOpen ? '-rotate-180' : ''} transition-all w-12`}
          />
        </div>
      </Dropdown>
    )
  )
}

export default NavLanguage
