export default function ArrowGreen({ style }) {
  return (
    <svg
      className={style}
      width='9'
      height='6'
      viewBox='0 0 9 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.2775 5.0275C4.0875 5.0275 3.8975 4.9575 3.7475 4.8075L0.2175 1.2775C-0.0725 0.987499 -0.0725 0.5075 0.2175 0.2175C0.5075 -0.0725 0.9875 -0.0725 1.2775 0.2175L4.2775 3.2175L7.2775 0.2175C7.5675 -0.0725 8.0475 -0.0725 8.3375 0.2175C8.6275 0.5075 8.6275 0.987499 8.3375 1.2775L4.8075 4.8075C4.6575 4.9575 4.4675 5.0275 4.2775 5.0275Z'
        fill='#262F39'
      />
    </svg>
  )
}
