export default function NavToggler({ style, onClick }) {
  return (
    <svg
      className={style}
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      width='23.251'
      height='13.806'
      viewBox='0 0 23.251 13.806'
    >
      <g
        id='vuesax_linear_menu'
        data-name='vuesax/linear/menu'
        transform='translate(-686 -386)'
      >
        <g id='menu' transform='translate(687 387)'>
          <path
            id='Vector'
            d='M14.239,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H14.239a1,1,0,0,1,1,1A1,1,0,0,1,14.239,1Z'
            fill='#140c3b'
          />
          <path
            id='Vector-2'
            data-name='Vector'
            d='M21.251,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H21.251a1,1,0,0,1,1,1A1,1,0,0,1,21.251,1Z'
            transform='translate(0 5.903)'
            fill='#140c3b'
          />
          <path
            id='Vector-3'
            data-name='Vector'
            d='M21.251,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H21.251a1,1,0,0,1,1,1A1,1,0,0,1,21.251,1Z'
            transform='translate(0 11.806)'
            fill='#140c3b'
          />
        </g>
      </g>
    </svg>
  )
}
