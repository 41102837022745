import { message, notification } from 'antd'

// THIS IS A GENERAL FUNCTION WHICH HANDLES SETTING FORMVALUES IN FORMDATA, BUT NO FILES INCLUDED.
// IT ASSUMES THAT ALL FILES ARE PRE-UPLOADED
export function appendFormValues(formData, formValues) {
  Object.entries(formValues).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      // Check for non-null and non-undefined values
      if (Array.isArray(value)) {
        // If the value is an array, append each item separately
        value.forEach((item) => {
          if (item !== null && item !== undefined) {
            // Also check each item in the array
            formData.append(`${key}[]`, item)
          }
        })
      } else {
        // Check if the value matches the DD/MM/YYYY date format
        const datePattern =
          /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/

        if (typeof value === 'string' && datePattern.test(value)) {
          // Replace '/' with '-' in the date string
          const modifiedDate = value.replace(/\//g, '-')
          formData.set(key, modifiedDate)
        } else {
          // If the value is not an array and does not match the date pattern, set it directly
          formData.set(key, value)
        }
      }
    }
  })
}

export const openNotification = (type, description) => {
  notification.open({
    closeIcon: null,
    description,
    type,
  })
}

export function calculateDiscountPercentage(totalPrice, discountAmount) {
  return (Number(discountAmount) / Number(totalPrice)) * 100
}

export const appendFiles = (fieldName, files) => {
  files.forEach((file) => {
    formData.append(`${fieldName}[]`, file.originFileObj)
  })
}

export const debounce = (func, wait) => {
  let timeout

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export const errorHandling = (error) => {
  if (error.response) {
    if (error.response.data.message) {
      return error.response.data.message
    }
  } else if (error.request) {
    if (!error.response) {
      return `Errore nella richiesta: ${error.message}`
    }
  } else {
    return error.message
  }
}

export const getAuthTokenFromCookie = (cookieHeader) => {
  const cookies = cookieHeader.split(';').reduce((acc, cookie) => {
    const [key, value] = cookie.trim().split('=')
    acc[key] = decodeURIComponent(value)
    return acc
  }, {})

  // Replace 'SMARTCAF_USER_JWT_TOKEN' with the actual name of your cookie
  const encodedToken = cookies['SMARTCAF_USER_JWT_TOKEN']
  return encodedToken ? decodeURIComponent(encodedToken) : null
}

export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      // Handle successful copying here, like showing a notification
      message.success('Testo copiato negli appunti')
    })
    .catch((err) => {
      // Handle errors here
      message.error('Failed to copy text: ', err)
    })
}