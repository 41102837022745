import Image from 'next/image'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { updateLanguage } from '../../redux/themeSlice'

import countries from '../../config/Countries'

const LanguageOverlay = ({ currentLanguage, setNavOpen }) => {
  const dispatch = useDispatch()
  const { push } = useRouter()

  const { profile } = useSelector((state) => state.auth)

  return (
    <div className='bg-white border border-gray_100 shadow-xs rounded'>
      {countries.map((e) => {
        if (e.code !== currentLanguage.code) {
          return (
            <div
              key={e.key}
              className='hover:bg-green_100 flex items-center p-8 border-b border-gray_100 cursor-pointer'
              onClick={() => {
                if (profile) {
                  dispatch(updateLanguage(e.code))
                }

                Cookies.set('i18next_SMARTCAF_LANG', e.code)
                push('/', undefined, { locale: e.code })
                setNavOpen(false)
              }}
            >
              <div className='w-[1.563rem] h-[1rem] relative cursor-pointer'>
                <Image src={e.image} alt={e.name} width={50} height={32} />
              </div>

              <span className='px-16 text-base font-medium'>{e.apprev}</span>
            </div>
          )
        }
        return null
      })}
    </div>
  )
}

export default LanguageOverlay
