import { useState, useEffect } from 'react'
import { Dropdown, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { queryNotifications, markAsSeen } from '../../redux/notificationSlice'
import pusher from '../../lib/pusher'

import Bell from '../svgs/Bell'
import NotificationList from '../shared-components/notifications/NotificationList'

const NavNotification = () => {
  const dispatch = useDispatch()
  const openNotification = (type, message, description) => {
    notification.open({
      message,
      description,
      type,
    })
  }

  const { profile } = useSelector((state) => state.auth)
  const { notifications } = useSelector((state) => state.notification)

  const [visible, setVisible] = useState(false)
  const [currentNotification, setCurrentNotification] = useState(null)
  const [currentNotificationId, setCurrentNotificationId] = useState(null)

  useEffect(() => {
    if (currentNotificationId)
      openNotification(
        'info',
        currentNotification.title,
        currentNotification.body
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNotificationId])

  useEffect(() => {
    dispatch(queryNotifications())
  }, [dispatch])

  useEffect(() => {
    if (profile && profile.id) {
      const channel = pusher.subscribe(`private-notificationuser.${profile.id}`)

      // Notification
      channel.bind('notification', function (data) {
        setCurrentNotification({
          id: data.notification.id,
          title: data.notification.title,
          body: data.notification.body,
        })
        setCurrentNotificationId(data.notification.id)
        dispatch(queryNotifications())
      })

      return () => {
        pusher.unsubscribe(`private-notificationuser.${profile?.id}`)
      }
    }
  }, [dispatch, profile])

  const handleVisibleChange = (flag) => {
    if (!flag) {
      dispatch(markAsSeen())
    }
    setVisible(flag)
  }

  return (
    <Dropdown
      className='ltr:ml-20 ltr:mr-40 rtl:ml-40 rtl:mr-20 cursor-pointer'
      placement='bottomRight'
      overlay={<NotificationList {...{ notifications }} />}
      trigger={['click']}
      open={visible}
      onOpenChange={handleVisibleChange}
    >
      <div
        className={`relative ${
          notifications && notifications[0]?.seen === 0
            ? "after:content-[''] after:absolute after:top-0 after:right-1 after:bg-green after:h-[10px] after:w-[10px] after:rounded-full after:border after:border-[#f7f8fc]"
            : ''
        }`}
      >
        <Bell style='w-28' />
      </div>
    </Dropdown>
  )
}

export default NavNotification
