import Pusher from 'pusher-js'
import Cookies from 'js-cookie'

const token = Cookies.get('SMARTCAF_USER_JWT_TOKEN')

const pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_APP_KEY, {
  cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER,
  authEndpoint: `${process.env.NEXT_PUBLIC_HOST}/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: token,
    },
  },
})

export default pusher
