import Link from 'next/link'
import { useTranslation } from 'next-i18next'

import NotificationBody from './NotificationBody'

const NotificationList = ({ notifications }) => {
  const { t } = useTranslation('common')

  return (
    <div className='bg-white shadow-notification rounded-xl w-[22.25rem]'>
      {notifications && (
        <div className='nav-notification-body max-h-[20rem] overflow-auto'>
          <NotificationBody {...{ notifications }} />
        </div>
      )}

      {notifications?.length > 0 ? (
        <div className='p-10 text-center border-t border-[#e6ebf1]'>
          <Link
            href='/dashboard/notifications'
            className='text-green font-medium text-sm'
          >
            {t('View all')}
          </Link>
        </div>
      ) : null}
    </div>
  )
}

export default NotificationList
