import { useRouter } from 'next/router'
import { useState } from 'react'
import { Dropdown, Avatar, Skeleton, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/authSlice'

import { UserOutlined } from '@ant-design/icons'
import Chevron from '../svgs/Chevron'
import ProfileIcon from '../svgs/navProfile/Profile'
import LogoutIcon from '../svgs/navProfile/Logout'
import OrdersIcon from '../svgs/navProfile/Orders'
import InquiryIcon from '../svgs/navProfile/Inquiry'
import PaymentIcon from '../svgs/navProfile/Payment'

const NavProfile = () => {
  const dispatch = useDispatch()
  const { push } = useRouter()

  const { profile, loading: profileLoading } = useSelector(
    (state) => state.auth
  )

  const [open, setOpen] = useState(false)

  const items = [
    {
      key: '1',
      label: (
        <div
          className='flex items-center gap-8'
          onClick={() => push('/dashboard/profile')}
        >
          <ProfileIcon style='w-24' />
          <span className='hover:text-black font-base font-semibold capitalize'>
            Profilo
          </span>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          className='flex items-center gap-8'
          onClick={() => push('/dashboard/requests')}
        >
          <OrdersIcon style='w-24' />
          <span className='hover:text-black font-base font-semibold capitalize'>
            Richieste
          </span>
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div
          className='flex items-center gap-8'
          onClick={() => push('/dashboard/payments')}
        >
          <PaymentIcon style='w-24' />
          <span className='hover:text-black font-base font-semibold capitalize'>
            Pagamenti
          </span>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div
          className='flex items-center gap-8'
          onClick={() => push('/dashboard/tickets')}
        >
          <InquiryIcon style='w-24' />
          <span className='hover:text-black font-base font-semibold capitalize'>
            Assistenza
          </span>
        </div>
      ),
    },
    {
      key: '5',
      label: (
        <div
          className='flex items-center gap-8'
          onClick={(e) => {
            e.preventDefault()
            dispatch(logout())
            push('/')
            window.location.href = '/'
          }}
        >
          <LogoutIcon style='w-24' />
          <span className='hover:text-black font-base font-semibold capitalize'>
            Esci
          </span>
        </div>
      ),
    },
  ]

  return profileLoading ? (
    <Space>
      <Skeleton.Avatar active={true} size='small' shape='circle' />
      <Skeleton.Input active={true} size='small' />
    </Space>
  ) : (
    <Dropdown
      trigger={['click']}
      onOpenChange={(open) => setOpen(open)}
      menu={{
        items,
      }}
    >
      <div className='p-[0.313rem] rounded flex items-center transition-all cursor-pointer'>
        {profile?.profile_photo ? (
          <div
            className='bg-cover bg-center w-40 h-40 rounded-full'
            style={{
              backgroundImage: `url(${process.env.NEXT_PUBLIC_IMG_PATH}${profile?.profile_photo})`,
            }}
          ></div>
        ) : (
          <Avatar shape='circle' size='default' icon={<UserOutlined />} />
        )}
        <p className='text-sm mx-8 mb-0'>
          {profile?.name} {profile?.lname}
        </p>

        <Chevron
          style={`w-12 mx-4 ${open ? '-rotate-180' : ''} transition-all w-16`}
        />
      </div>
    </Dropdown>
  )
}

export default NavProfile
