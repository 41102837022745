export default function CloseModal({ style, onClick }) {
  return (
    <svg
      className={style}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      onClick={onClick}
    >
      <path
        d='M18 6L6 18'
        stroke='#BDBDBD'
        strokeWidth='1.8'
        strokeLinecap='round'
      />
      <path
        d='M18 18L6 6'
        stroke='#BDBDBD'
        strokeWidth='1.8'
        strokeLinecap='round'
      />
    </svg>
  )
}
